import React, {useEffect} from 'react'
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import axios from "axios";

export default function ExportToExcel({fileName, dataOf, salaryType, excelData, slate_type}) {
    let listData = []
    const [apiData, setApiData] = React.useState([])
    const [newData, setnewData] = React.useState([])
    //const isFirstRender = React.useRef(true);
    useEffect((props) => {
        if (dataOf === 'NBA') {
            setApiData(excelData)
        }
        if (dataOf === 'NFL') {
            setApiData(excelData)
        }
    })
    const getData = () => {
        axios.post('https://8pf6sev9de.execute-api.us-east-2.amazonaws.com/v1', {
            sport: dataOf.toLowerCase(),
            salary: salaryType.toLowerCase(),
            slate: slate_type
        })
            .then((res) => {
                exportToCSV(apiData, fileName, dataOf, salaryType, res.data.body)
            });
    }


    function xyz(value) {
        if (typeof value === "undefined" || typeof value === undefined) {
            return 0
        } else {
            return value
        }
    }

    const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const exportToCSV = (apiData, fileName, dataOf, salaryType, responseData) => {
        console.log(responseData)
        // if(dataOf === 'NFL' ){
        //     listData = [['QB', 'RB', 'RB', 'WR', 'WR', 'WR', 'TE', 'FLEX', 'DST']]
        // }
        if (dataOf === 'NFL' && salaryType === 'dk') {
            listData = [['CPT', 'FLEX', 'FLEX', 'FLEX', 'FLEX', 'FLEX']]
        }
        if (dataOf === 'NFL' && salaryType === 'fd') {
            listData = [['MVP', 'FLEX', 'FLEX', 'FLEX', 'FLEX']]
        }
        if (dataOf === 'NBA' && salaryType === 'dk') {
            listData = [['PG', 'SG', 'SF', 'PF', 'C', 'G', 'F', 'UTIL']]
        }
        if (dataOf === 'NBA' && salaryType === 'fd') {
            listData = [['PG', 'PG', 'SG', 'SG', 'SF', 'SF', 'PF', 'PF', 'C']]
        }

        Object.keys(apiData).map((item) => {
            var temp = []
            Object.keys(apiData[item]['Players']).map((insideItem) => {

                temp.push(apiData[item]["Players"][insideItem]['contest_id'])
            })
            listData.push(temp)
        })
        console.log(listData)
        // console.log(responseData)
        if(listData.length !== responseData.length){
            var math_value = Math.abs(listData.length - responseData.length)
            var l = []
            for (var i = 0; i < listData[0].length; i++){
                l.push('')
            }
            // let forpush = ['','',''];
            for (let i = 0 ; i< math_value; i++ ){
               listData.push(l)

            }


        }
        console.log(listData)
        console.log(responseData)
        var list = []
        for (var i=0; i < listData.length;i++){
            list.push(listData[i].concat(responseData[i]))
        }
        console.log(list)

        const ws = XLSX.utils.aoa_to_sheet(list);
        const wb = {Sheets: {data: ws}, SheetNames: ["data"]};
        const excelBuffer = XLSX.write(wb, {bookType: "xlsx", type: "array"});
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, fileName + fileExtension);
    };
    return (
        <button className="btn btn-primary active ad-group-btn"
                style={{position: "relative", top: "4%", left: "10%"}}
            // onClick={(e) => exportToCSV(apiData, fileName, dataOf, salaryType)}
                onClick={getData}

        >Export</button>
    );
};